import '../css/login.css';

// 设置高度
let windowInnerHeight = window.innerHeight;
let loginWrapHeight = $('.login-wrap').height();
$('body').height(windowInnerHeight);
$('.login-wrap').css('padding-top', `${(windowInnerHeight - loginWrapHeight) / 2 - 50}px`);

// // 延迟后重新计算一次到顶部的高度，防止加载速度影响
setTimeout(() => {
    windowInnerHeight = window.innerHeight;
    loginWrapHeight = $('.login-wrap').height();
    $('body').height(windowInnerHeight);
    $('.login-wrap').css('padding-top', `${(windowInnerHeight - loginWrapHeight) / 2 - 50}px`);
}, 500);

// 加载背景图片 适配屏幕大小，将背景的高宽比和图片的高宽比进行对比后决定背景图片的高和宽
if (window.innerWidth / windowInnerHeight > 1920 / 1080) {
    $('body').css({ "background-image": `url(${staticRoot}/img/bg.jpg)`, "background-repeat": "no-repeat", "background-position": "center top", "background-size": "100% auto", "min-width": "1000px" });
} else {
    $('body').css({ "background-image": `url(${staticRoot}/img/bg.jpg)`, "background-repeat": "no-repeat", "background-position": "center top", "background-size": "auto 100%" });
}

layui.use(['form', 'layer', 'yznForm'], function () {
    var form = layui.form,
        layer = layui.layer,
        yznForm = layui.yznForm;

    yznForm.listen('', function (res) {
        layer.msg(res.msg, {
            offset: '15px',
            icon: 1,
            time: 1000
        }, function () {
            window.location.href = res.url;
        });
        return false;
    }, function (res) {
        //刷新验证码
        $("#verify").click();
        //layer.msg(res.msg, { icon: 5 });
    });
});
//刷新验证码
$("#verify").click(function () {
    var verifyimg = $("#verify").attr("src");
    $("#verify").attr("src", verifyimg.replace(/\?.*$/, '') + '?' + Math.random());
});